import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {dracula, dark, nord, nightOwl, oneLight, duotoneLight} from 'react-syntax-highlighter/dist/esm/styles/prism';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Row, Col, Typography, Layout, Space, Switch, Select, Divider, Spin} from "antd";

import MarkdownEditor from "react-markdown-editor-lite";
import MarkdownViewer from "./MarkdownViewer3";


const themes = {
    'dracula': dracula,
    'dark': dark,
    'nord': nord,
    'nightOwl': nightOwl,
    'oneLight': oneLight,
    'duotoneLight': duotoneLight
}

// Define your markdown files here
const markdownFiles = [
    {
        title: 'About the O notation',
        url: 'https://logistic-bucket.nyc3.cdn.digitaloceanspaces.com/markdown_files_dir/O_NOTATION.md'
    },
    {
        title: 'Bubble sort',
        url: 'https://logistic-bucket.nyc3.cdn.digitaloceanspaces.com/markdown_files_dir/BUBBLE_SORT.md'
    },
    {
        title: 'Selection sort',
        url: 'https://logistic-bucket.nyc3.cdn.digitaloceanspaces.com/markdown_files_dir/SELECTION_SORT.md'
    },
    {
        title: 'Explanation',
        url: 'https://logistic-bucket.nyc3.cdn.digitaloceanspaces.com/markdown_files_dir/EXPLANATION.md'
    },
];

// const markdownFiles = [
//     {
//         title: 'Bubble sort',
//         url: 'http://127.0.0.1:8000/api/v0/markdown/1/get_file_content/'
//     },
//     {
//         title: 'Selection sort',
//         url: 'http://127.0.0.1:8000/api/v0/markdown/2/get_file_content/'
//     },
// ];


const App = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [editMode, setEditMode] = useState(false);

    const [markdown, setMarkdown] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    const [themeForCodeHighLighter, setThemeForCodeHighLighter] = useState(themes['oneLight']);

    useEffect(() => {

        if (selectedFile) {
            axios.get(process.env.PUBLIC_URL + selectedFile, {
                // Important headers to support CORS. This guys must be set on DO Spaces too in CORS section
                headers: {
                    'Accept': 'text/markdown',
                    'Cache-Control': 'no-cache',
                    'Content-Type': 'text/markdown',
                },
                responseType: 'text'
            })
                .then(res => {
                    setMarkdown(res.data);
                })
                .catch(err => {
                    console.error(err);
                }).finally(() => {
                setIsLoading(false);
            });
        }

    }, [selectedFile]);

    const handleEditorChange = ({html, text}) => {
        setMarkdown(text);
    };

    const components = {
        code({node, inline, className, children, ...props}) {
            const match = /language-(\w+)/.exec(className || '')

            return !inline && match ? (
                <div style={{position: 'relative'}}>
                    <CopyToClipboard text={children}>
                        <button style={{position: 'absolute', right: 0}}>Copy</button>
                    </CopyToClipboard>

                    <SyntaxHighlighter
                        style={themeForCodeHighLighter}
                        language={match[1]}
                        PreTag="div"
                        children={String(children).replace(/\n$/, '')} {...props}
                    />
                </div>
            ) : (
                <code className={className} {...props}>
                    {children}
                </code>
            )
        }
    };

    const handleChange = ({value}) => {
        console.log(value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }


        setThemeForCodeHighLighter(themes[value]);
    };

    return <Layout>
        <Row>

            {editMode
                ? <Col sm={8}>
                    <h1>Edit Mode</h1>
                    <MarkdownEditor
                        value={markdown}
                        onChange={handleEditorChange}
                        style={{minHeight: "500px"}}
                        config={{
                            view: {menu: true, md: true, html: false}, // Hide the HTML preview pane
                        }}
                    />
                </Col>

                : <Col sm={8}>
                    <Typography.Title level={1}>
                        My custom markdown viewer
                    </Typography.Title>

                    {/* Render a list of links */}
                    {markdownFiles.map((file, index) => (
                        <p key={index}>
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                setSelectedFile(file.url);
                            }}>
                                {file.title}
                            </a>
                        </p>
                    ))}

                </Col>
            }

            <Col sm={16}>
                <Spin spinning={isLoading} tip="Loading...">
                    <Space>
                        <Select
                            labelInValue
                            defaultValue={{
                                value: 'oneLight',
                                label: 'One Light',
                            }}
                            style={{
                                width: "200px",
                            }}
                            onChange={handleChange}
                            options={[
                                {
                                    value: 'oneLight',
                                    label: 'One Light',
                                },
                                {
                                    value: 'dracula',
                                    label: 'Dracula',
                                },
                                {
                                    value: 'dark',
                                    label: 'Dark',
                                },
                                {
                                    value: 'nord',
                                    label: 'Nord',
                                },
                                {
                                    value: 'nightOwl',
                                    label: 'Night Owl',
                                },
                                {
                                    value: 'duotoneLight',
                                    label: 'Duotone Light',
                                }
                            ]}
                        />

                        <Switch checkedChildren="Edit Mode" unCheckedChildren="View Mode" onChange={setEditMode}/>
                    </Space>


                    <Typography.Title level={2}>
                        {markdownFiles.find(x => x.url === selectedFile)?.title}
                    </Typography.Title>
                    <MarkdownViewer components={components} markdown={markdown}/>

                </Spin>
            </Col>
        </Row>

    </Layout>;
}

export default App;