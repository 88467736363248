import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';

const MarkdownViewer = ({components, markdown}) => {
    return (
        <div>
            <ReactMarkdown
                components={components}
                remarkPlugins={[remarkMath]}
                rehypePlugins={[rehypeKatex]}
                children={markdown}
            />
        </div>
    );
};

export default MarkdownViewer;